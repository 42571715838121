<template>
    <h1
        v-if="variant === 'home'"
        ref="logo"
        class="main-hero__logo"
        :class="{
            'main-hero__logo--hide': navigationStore.mainMenuOpen
        }"
        :style="variant === 'home' ? `animation-delay: ${animationDelay}; --logoWidth: ${logoWidth}; --scaleFactor: ${scaleFactor}; animation-name: ${animationName};` : ''"
        :data-in-navbar="logoIsInNavBar"
    >
        <NuxtLink
            v-if="!((openDropdowns?.length && logoIsInNavBar) && windowWidth < 1200)"
            :to="logoIsInNavBar ? localePath('/') : undefined"
        >
            <TheLogo />
        </NuxtLink>
    </h1>
    <header
        v-bind="$attrs"
        ref="hero"
        class="main-hero"
        :class="`main-hero--${variant} main-hero--${mediaType}`"
    >
        <div
            ref="content"
            class="main-hero__content"
        >
            <template
                v-if="pending && !video"
            >
                <template v-if="variant === 'home'">
                    <Skeletor
                        v-for="i in [1,2,3,4]"
                        :key="i"
                        class="main-hero__image"
                        width="460"
                        height="100%"
                        :shimmer="false"
                    />
                </template>
                <template v-else-if="variant === 'property'">
                    <Skeletor
                        v-for="i in [1,2,3]"
                        :key="i"
                        class="main-hero__image"
                        width="460"
                        height="100%"
                        :shimmer="false"
                    />
                </template>
            </template>

            <template
                v-if="pending && video"
            >
                <Skeletor
                    class="main-hero__video"
                    width="100%"
                    height="100%"
                    :shimmer="false"
                />
            </template>

            <div
                v-if="mediaType === 'video'"
                class="main-hero__video"
            >
                <BaseVideo
                    :mp4="videoMp4?.[0]?.url"
                    :webm="videoWebM?.[0]?.url"
                />
            </div>
            <div
                v-if="mediaType === 'image'"
                class="main-hero__images"
            >
                <BaseImage
                    v-for="(image, index) in images"
                    ref="imageElements"
                    :key="index"
                    class="main-hero__image"
                    :image="image"
                    :sizes="getImageSizes(index)"
                    loading="eager"
                    fetchpriority="high"
                    :fade-in-view="false"
                />
            </div>

            <hgroup
                ref="cta"
                class="main-hero__cta"
            >
                <slot name="cta" />
            </hgroup>
        </div>
    </header>
</template>

<script setup lang="ts">
import { useElementSize, useWindowSize } from '@vueuse/core';

import type { ColorPalette } from '~/composables/useColorPalette';
import { useColorPalette } from '~/composables/useColorPalette';
import { useNavigationStore } from '@/store/navigation';
import { color } from '~/assets/styles/css-variables';

const navigationStore = useNavigationStore();
const { resolveColor, getTextColor } = useColorPalette();
const localePath = useLocalePath();

interface ImageObject {
    id: string;
    url?: string;
    alt?: string;
    title?: string;
    width: string;
    height: string;
    [key: string]: string | undefined;
}

interface VideoObject {
    url?: string;
    title?: string;
}

interface Props {
    backgroundColor?: keyof ColorPalette;
    images?: ImageObject[];
    videoMp4?: VideoObject[];
    videoWebM?: VideoObject[];
    variant: 'property' | 'home';
    pending?: boolean;
    loadingStateShown?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    backgroundColor: () => 'oasis-green',
    images: undefined,
    videoMp4: undefined,
    videoWebM: undefined,
    videoPlaceholderImage: undefined,
    loadingStateShown: false
});

const backgroundColor = computed(() => resolveColor(props.backgroundColor));
const textColor = computed(() => getTextColor(props.backgroundColor, 'lightest'));

const navigationBackgroundColor = computed(() => {
    // A light text color implies a dark background color, set a light background color for the navigation
    if (textColor.value === color.lightest || textColor.value === color.lime) {
        return 'var(--color-cream)';
    } else {
        return backgroundColor.value;
    }
});

/** Set a global accent color, so it may be re-used in for example the header bar background */
useHead({
    style: () => [
        `:root {
            --accent-color: ${backgroundColor?.value};
            --navigation-text-color: ${textColor?.value};
            --navigation-background: ${navigationBackgroundColor?.value};
        }`
    ]
});

function getImageSizes(index: Number) {
    const desktopWidth = 418;
    let mobileWidth = 113;

    if (props.variant === 'property') {
        if (index === 2) {
            mobileWidth = 227;
        }
    }
    return `(min-width: 1440px) ${desktopWidth}px, (min-width: 768px) ${mobileWidth*2}px, ${mobileWidth}px`;
}
/** Animations  */
const hero = ref();
const logo = ref();
const imageElements = ref([]);
const content = ref();
const cta = ref();
const animationName = ref('none');


const video = computed(() => {
    return props.videoMp4 || props.videoWebM;
});

const mediaType = computed(() => {
    if (video?.value && video?.value?.length > 0) {
        return 'video';
    }

    return 'image';
});

const { width: logoWidth } = useElementSize(logo);
const { width: windowWidth } = useWindowSize();

const logoIsInNavBar = ref(false);
// Set a scrollPercentage property as a CSS variable on the body element
const setPercentage = ref(0);

const setHeroScrollPercentage = function() {
    if (window.scrollY <= 0) {
        setPercentage.value = 0;
        animationName.value = 'none';
        return;
    }

    const percentage = window.scrollY / toValue(hero)?.offsetHeight;

    if (animationName.value === 'none' && logoWidth.value > 0) {
        animationName.value = 'moveUp';
    }

    logoIsInNavBar.value = percentage >= .9;
    if (percentage <= 1) {
        setPercentage.value = percentage;
    } else if (percentage >= 1 && setPercentage.value < 1) {
        setPercentage.value = 1;
    }
};

const animationDelay = computed(() => {
    return (setPercentage.value * -1).toString() + 's';
});

// eslint-disable-next-line no-var, vars-on-top
declare var loadingAnimationEnded: Boolean;

const parallaxApplied = ref(false);

function applyParallax() {
    if (parallaxApplied.value || !hero.value || !content.value || !cta.value) {
        return;
    }

    const { parallax } = useScrollAnimations();

    parallax(content.value, {
        trigger: hero.value,
        direction: 'down'
    });

    parallax(cta.value, {
        trigger: hero.value,
        offset: .8
    });

    parallaxApplied.value = true;
}

import { useDropdownStore } from '@/store/dropdown';
const { dropdownOpen, openDropdowns } = useDropdownStore();

onMounted(() => {
    if (typeof loadingAnimationEnded === 'undefined' || loadingAnimationEnded === true) {
        applyParallax();
    } else if (loadingAnimationEnded === false) {
        document.querySelector('.main-hero')?.addEventListener('animationend', () => {
            applyParallax();
        });

        if (props.loadingStateShown === true) {
            applyParallax();
        }
    }

    if (props.variant === 'home') {
        // Set the scroll percentage
        setHeroScrollPercentage();
        window.addEventListener('scroll', setHeroScrollPercentage);
    }
});

const scaleFactor = computed(() => {
    if (logoWidth.value === 0) {
        nextTick(() => {
            if (windowWidth.value < 768) {
                return 113 / logoWidth.value;
            }

            return 207 / logoWidth.value;
        });
    }

    if (windowWidth.value < 768) {
        return 113 / logoWidth.value;
    }

    return 207 / logoWidth.value;
});

onBeforeUnmount(() => {
    if (props.variant === 'home') {
        window.removeEventListener('scroll', setHeroScrollPercentage);
    }

    document.querySelector('.main-hero')?.removeEventListener('animationend', () => {
        applyParallax();
    });
});
</script>

<style lang="less">
.main-hero {
    position: relative;
    z-index: 1;

    height: 79vh;
    min-height: 500px;
    overflow: hidden;

    background-color: var(--accent-color);
    color: var(--navigation-text-color);
    transform: translateY(0);
    top: calc(var(--banner-height) * -1);

    @media @q-md-min {
        min-height: 750px;
    }
}

.main-hero__content {
    .base-grid(var(--grid-maxWidth-page), 4);
    transform: translateZ(0); //prevents rounding layout shift on hydrate
}

.main-hero__logo {
    --targetWidth: 111;
    --topOffset: .875rem;

    position: absolute;
    top: clamp(14.5rem, 50vh, calc(30svh + var(--banner-height, 0vh)));
    left: 0;
    right: 0;
    z-index: 200;

    width: 100%;
    max-width: 87svw;

    transform-origin: center center;
    /* stylelint-disable-next-line declaration-no-important */
    margin: .65rem auto !important;

    text-align: center;

    color: var(--color-lightest);
    pointer-events: none;

    &[data-in-navbar='true'] {
        color: var(--color-darkest);
        z-index: 310;
    }

    // Don't make logo clickable when not in navbar, fix for hero play button
    &[data-in-navbar='false'] > a{
        @media @q-md-max {
            pointer-events: none;
        }
    }

    & > a {
        display: inline-block;
        color: inherit;
        height: 100%;
        width: 100%;
        pointer-events: all;
        aspect-ratio: 63.6875 / 13.5;
    }

    .the-logo {
        height: 100%;
        width: 100%;
    }

    &:visited {
        color: inherit;
    }

    [data-show-loading-state='false'] & {
        animation-name: none;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-iteration-count: 1;
        animation-fill-mode: both;
        animation-play-state: paused;
        // Set a delay as the percentage scrolled as negative value
        // For example: 0.4 * -1 = -0.4s delay
        // ^ Moved this to a style attr, but kept here as reference
        // animation-delay: calc(var(--scrollPercentage) * -1s);
    }

    @media @q-md-min {
        --topOffset: 1.375rem;
        --targetWidth: 207;

        top: calc(39svh + var(--banner-height, 0vh));
        /* stylelint-disable-next-line declaration-no-important */
        margin-top: .9rem !important;
        max-width: 63rem;
    }


    @keyframes moveUp {
        from {
            position: fixed;
            transform: translateY(0) scale(1);
        }

        to {
            position: fixed;
            top: var(--topOffset);
            transform: translateY(-50%) scale(var(--scaleFactor));
        }
    }
}

.main-hero__logo--hide {
    opacity: 0;
    pointer-events: none;
    z-index: -1;
}

.main-hero__cta {
    margin-top: calc(var(--navbar-height) + var(--spacing-sm));
    padding-top: var(--banner-height);
    grid-column: first / third;
    grid-row: 1/2;
    z-index: 9999;
    gap: var(--spacing-md);

    @media @q-md-min {
        margin-top: var(--grid-gutter-margin);
        grid-column: third;
        grid-row: 1/3;
    }

    .call-to-action__button {
        @media @q-sm-max {
            position: absolute;
            left: var(--grid-gutter-margin);
            right: var(--grid-gutter-margin);
            bottom: var(--grid-gutter-margin);
            width: calc(100% - (var(--grid-gutter-margin) * 2));
            justify-content: center;
        }
    }
}

.main-hero__video {
    grid-column: 1/-1;
    grid-row: 1/-1;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // object-position: left;
    }
}

.main-hero__images {
    .base-grid(var(--grid-maxWidth-page), 4);
    width: 100%;

    grid-column: 1/-1;
    grid-row: 1/5;

    @media @q-sm-max {
        grid-row: 3/5;
    }

    .main-hero--property & {
        grid-row: 1/5;

        @media @q-md-max {
            height: 100vh;
            max-height: 60.5vh;
        }
    }
}

.main-hero__image {
    display: flex;
    overflow: hidden;
    align-self: start;
    height: auto;
    max-height: 100%;

    &:nth-child(1) {
        grid-column: left-side-gutter/left-gutter;
        grid-row: 1/4;
        align-self: end;
        position: relative;
        top: -30%;

        img {
            object-position: bottom center;
        }

        @media @q-md-max {
            align-self: end;
            grid-row: 1/4;
            margin-bottom: var(--grid-gutter-margin);
        }
    }

    &:nth-child(2) {
        position: relative;

        grid-column: left-side-gutter/left-gutter;
        grid-row: 3/5;
        align-self: end;

        @media @q-md-max {
            grid-row: 4/5;
            height: 100%;
            align-self: start;

            .base-image__image {
                height: 100%;
            }
        }
    }

    &:nth-child(3) {
        grid-column: second;
        grid-row: 3/5;
        grid-row: 2/5;
        align-self: end;

        @media @q-sm-max {
            margin-bottom: var(--grid-gutter-margin);
        }
    }

    &:nth-child(4) {
        grid-column: third/-1;
        grid-row: 3/5;
        align-self: end;
    }

    picture {
        overflow: hidden;
        width: 100%;
    }

    img {
        height: auto;
        max-height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}

/** Modifiers */
.main-hero--property {
    .main-hero__image {
        &:nth-child(1) {
            grid-row: 1/2;
            align-self: start;
            position: static;
            top: 0;

            @media @q-md-min {
                grid-row: 1/3;
            }
        }

        &:nth-child(2) {
            grid-row: 2;
            align-self: start;
            margin-top: var(--grid-gutter-margin);

            @media @q-md-min {
                grid-row: 3/5;
            }
        }

        &:nth-child(3) {
            grid-column: second/-1;
            grid-row: 1/4;
            align-self: end;

            @media @q-md-min {
                grid-column: second;
                grid-row: 2/5;
            }
        }
    }

    .main-hero__cta {
        grid-column: first / right-side-gutter;
        grid-row: 2/-1;
        margin-bottom: var(--spacing-md);
        margin-top: 0;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @media @q-md-min {
            grid-column: third;
            grid-row: 3/5;
            margin-bottom: var(--grid-gutter-margin);
            align-self: end;
        }

        .call-to-action {
            align-items: flex-end;
        }

        .call-to-action__button {
            @media @q-md-max {
                position: static;
                width: 100%;
                justify-content: center;
            }
        }
    }
}

.main-hero--video {
    @media @q-md-max {
        height: 86vh;
    }

    .main-hero__content {
        max-width: none;
        margin: 0;

        @media @q-sm-max {
            .base-grid(var(--grid-maxWidth-page), 5);
        }
    }

    &.main-hero--property .main-hero__video {
        grid-row: 2/-1;
        grid-column: 2/5;

        @media @q-md-max {
            padding-top: var(--navbar-height);

            grid-column: 1/-1;
            grid-row: 1/3;
        }
    }
}

.main-hero__video-position--left {
    object-position: left;
}

.main-hero__video-position--right {
    object-position: right;
}

</style>
